import React from 'react';
import TileList from './TileList';
import { Link } from 'react-router-dom';
import './Home.css';


const Home = ({history, vPage, isLoggedOn}) =>{
	return(
		<div>
			<h1 className="headding">Home</h1>
			<div className="ma3">
				<h2><q className="i ttc">Australia's leading supplier of motor vehicles to the movie & media industry</q></h2>
			</div>
			<div className="container bt bt--solid bw1">
				<div className="ma3">
					<div className="heading">
						<h1>Featured Vehicles</h1>
					</div>
					<div className="flex-wrap featuredAliginment">
						<TileList history={history} vPage={vPage} isLoggedOn={isLoggedOn}/>
					</div>
					<div className="ma3 vh20 cover-flex">
						<div className="ma3 lh-title">
							<h3 className="f1 fw5 ttu">Wondering how to order a car?</h3>
							<h4 className="f2 fw6">Order a car by <Link to="/contact">contacting us here</Link></h4>
						</div>
					</div>
				</div>
				<div className="bt bt--solid bw1 cover cover-flex cover-b">
					<div className="ma5">
						<h1 className="f-headline ttu">What is H M Movie Motors?</h1>
						<p className="i f1">
							"We are a car leasing company created to lease our wide range
							of vehicles to the movie and media industry."
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;