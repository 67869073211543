import React, {useState, useEffect} from 'react';
// import Data from '../../temp/Data';
import Tiles from './Tiles';

//this is all very similar to card list possbily merge both?
const TileList = (props) => {
	const [data, setData] = useState(null);
	useEffect(() => {
		let controller = new AbortController();
		fetchData(controller)
			.then(value => setData(value))
			.catch(err => console.error(err));
		return () => controller?.abort();
	}, [])
	return data&&typeof data !== 'string'?
		renderTiles(data, props):renderNothing();
}

const renderTiles = (data, props) => {
	return(
		<>
			{
				data.filter(info => info.pub === 'true' || (window.sessionStorage.getItem(process.env.REACT_APP_TOKEN) && props.isLoggedOn))
				.map((info, i) => {
					return(
						<Tiles
						key={i}
						id={info.id}
						name={info.name}
						isAvailable={info.available === 'true'?true:false}
						available={info.availwhen.slice(0,10)}
						pageSource={props.vPage}
						iconUrl={info.iconurl} 
						history={props.history}
						pub={info.pub}/>
						)
				})
			}
		</>
	)
}

const renderNothing = () => {
	return(<div>Something went wrong</div>)
}

//repeating code with card list and app
async function fetchData(controller){
	// temp
	// return new Promise((resolve, reject) => {
	// 	if(Data)
	// 		resolve(Data);
	// 	else
	// 		reject('no data');
	// })
	
	const token = window.sessionStorage.getItem(process.env.REACT_APP_TOKEN);
	return await fetch("https://sevotechserver.xyz/vehiclefeat", {
			method: "get",
			headers: {
				'content-Type': 'application/json',
				'Authorization': token?token:''
			},
			signal: controller.signal
			}).then(res => res.json())
			.then(data => Promise.resolve(data))
			.catch(err => Promise.reject(`TileList.js Unable to fetch, ${err}`));
	
}

export default TileList;