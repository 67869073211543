import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import 'tachyons';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import history from './history';

// const history = createBrowserHistory();

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<div>Loading</div>}>
			<Router basename={process.env.PUBLIC_URL} history={history}>
				<App history={history}/>
			</Router>
		</Suspense>
	</React.StrictMode>, 
	document.getElementById('root') );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
