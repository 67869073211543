import React, {Component} from 'react';

export default class ErrorBoundry extends Component{
	constructor(props){
		super(props);
		this.state = { hasError: false};
	}

	static getDerivedStateFromError(error){
		return { hasError: true };
	}
	componentDidCatch(error, errorInfo){
		console.error(errorInfo);
	}
	render(){
		if(this.state.hasError){
			return (<h1>Uh oh something went wrong :/</h1>)
		}
		return this.props.children;
	}
}