import React, {useState} from 'react';
import { Link, withRouter } from "react-router-dom";
import './NavBar.css';
import coverImage from './CoverImage.JPG';

const NavBar = (props) => {
	const [navOpen, setNavOpen] = useState(false);
	return(
		<>
			<div className='navigation'>
				<nav>
					<div className='brand'>
						<div>
							<Link className='nav-link nav-brand' to='/'>
								H M Movie Motors
							</Link>
						</div>
						<div className='nav-list-div'>
							<div className='nav-open' onClick={() => setNavOpen(!navOpen)}>
								<div className='line1'></div>
								<div className='line2'></div>
								<div className='line3'></div>
							</div>
							<ul className={`nav-list ${navOpen === false 
								? 'hide-nav' : null}`}>
								<li  className={`nav-item ${
	                  				props.location.pathname === "/" 
	                  				? "active" : "" }`}>
	                				<Link className="nav-link" to="/">
	                 				 Home
	                 				 </Link>
	                			</li>
								<li  className={`nav-item  ${
	                  				props.location.pathname === "/catalogue" 
	                  				? "active" : "" }`}>
									<Link className='nav-link' to='/catalogue'>
										Vehicle Catalogue
									</Link>
								</li> 
								<li  className={`nav-item  ${
	                  				props.location.pathname === "/about" 
	                  				? "active" : "" }`}>
									<Link className="nav-link" to="/about">
	                  					About
	                				</Link>
								</li>
								<li  className={`nav-item  ${
	                  				props.location.pathname === "/contact" 
	                  				? "active" : "" }`}>
									<Link className='nav-link' to='/contact'>
										Contact Us
									</Link>
								</li>
								<li  className={`nav-item  ${
	                  				props.location.pathname === "/login" 
	                  				? "active" : "" } ${props.isLoggedOn === false ? null : 'hide'}`}>
									<Link className='nav-link' to='/login'>
										Login
									</Link>
								</li> 
								<li className={`nav-item ${props.isLoggedOn === true ? null : 'hide'}`}>
									<div onClick={props.logOut}>
										log Out
									</div>
								</li>
							</ul>
						</div>
					</div>	
				</nav>
				
			</div>
			<div className='logo'>
				<img alt='logo' src={coverImage} width='150px' height='auto'/>
			</div>
		{/*Logo goes here for now*/}
		</>
	);
}

export default withRouter(NavBar) 