import React from "react";

const NotFound = () => {
	return(
		<>
			<h1>Error 404 Page not Found!</h1>
			
			<h2>Troubleshoot:</h2>
			<ol>
				<li>Refresh the page</li>
				<li>Go back to site and try again.</li>
			</ol>
		</>
		)
}

export default NotFound;