import React, {useState, useEffect} from 'react';

const Tiles = ({id, name, iconUrl, isAvailable, pageSource, available, history, pub}) =>{
	const [tileImage, setTileImage] = useState(null);

	useEffect(() => {
		if(iconUrl)
			setTileImage(process.env.PUBLIC_URL + iconUrl);
		else
			setTileImage(process.env.PUBLIC_URL + '/Images/Preview.jpg')
	}, [iconUrl])

	return(
		<div onClick={() => pageSource(id)} className={`tile hover shadow-3 pointer ${pub!=='true'?'o-50':''}`}>
				<img src={tileImage} alt={name} className={`img ${!tileImage||tileImage === '/Images/Preview.jpg'?'imgContain':''}`} onError={() => setTileImage(process.env.PUBLIC_URL + '/Images/Preview.jpg')}/>
			{/*<div className="infoPannel">*/}
				<h4 className={`fw7 c-r fontsizer`}>{name}</h4>
				<div className={`${isAvailable === true 
					? 'green round tileBubble'
					: 'red round tileBubble'}`}>
					<h5 className="f3 fw6">{`${isAvailable === true 
									? 'Currently Available' 
									: 'Currently Not Available'}`}</h5>
					<h5 className={`f3 fw6 ${available.match('1999')>0?'hide_item':''}`}> {`Until: ${available}`} </h5>
				</div>
			{/*</div>*/}
		</div>
	)
}

export default Tiles;