import React, {useState} from 'react';
import history from '../../history';

const initialUserState = {
	email: '',
	pass: '',
	code: ''
}

async function sumbitFetch(user, url){
	// console.log(user);
	return await fetch(`https://sevotechserver.xyz/${url}`, {
			method: 'post',
			headers: {'content-Type': 'application/json'},
			body: JSON.stringify({
				email: user.email,
				pass: user.pass,
				code: user.code
			})
		}).then(res => res.json())
		.then(data => Promise.resolve(data))
		.catch(err => Promise.reject(`login.js: error fetching, ${err}`));
}

const Login = (props) => {

	const [user, setUser] = useState(initialUserState);
	const [isSignin, setIsSignin] = useState(true);
	const [sumbitError, setSubmitError] = useState(false);

	//might be able to pull this pout of this snippet
	const onSubmit = () => {
		sumbitFetch(user, isSignin?'login':'register')
		.then(response => {
			console.log(response.user);
			if((response && response.id) || (response && response.user)){
				if(isSignin){
					setUser(initialUserState);//this may be an issue
					props.signIn(response);
					window.sessionStorage.setItem(process.env.REACT_APP_TOKEN, response.token);
					history.replace('/');
				}
				else window.location.reload();
			}
			else setSubmitError(true);
		})
		.catch(err => console.error(err));
	}

	return(
		<div>
			<main className="br3 ba b--black-10 mv4 w-100 w-50-m w-25-1 mw6 shadow-5 center">
			  <div className="pa4">
			    <fieldset id="sign_up" className="">
			    	<h2>This form is for Administration only</h2>
			      <legend className="f1 fw6">{`${isSignin ? 'Sign In' : 'Register'}`}</legend>
			      <div className={`' ba bw1 b--black shadow-2' 
			      ${sumbitError === true ? null : 'dn'}`}>
			      	<p className="dark-red">{`${isSignin ? 'Inccorect email or password' : 'incorrect email or admin code'}`}</p>
			      </div>
			      <div className='mv3'>
			        <label className="db f3 lh-title" htmlFor="email-address">Email</label>
			        <input 
			        className={`ba bw2 shadow-2 pa2 ${sumbitError === true ? 'b--red' : 'b--black'}`}
			        type="email" 
			        name="email-address"  
			        id="email-address" 
			        onChange={(event) => setUser({...user, email: event.target.value})} />
			      </div>
			      <div className='mv3'>
			        <label className="db f3 lh-title" htmlFor="password">Password</label>
			        <input 
			        className={`ba bw2 shadow-2 pa2 ${sumbitError === true ? 'b--red' : 'b--black'}`} 
			        type="password" 
			        name="password"  
			        id="password" 
			        onChange={(event) => setUser({...user, pass: event.target.value})} />
			      </div>
			      <div className={`mv3 ${isSignin ? 'hide' : null}`}>
			        <label className="db f3 lh-title" htmlFor="code">Admin Code</label>
			        <input 
			        className={`ba bw2 shadow-2 pa2 ${sumbitError === true ? 'b--red' : 'b--black'}`} 
			        type="password" 
			        name="code"  
			        id="code" 
			        onChange={(event) => setUser({...user, code: event.target.value})} />
			      </div>
			    </fieldset>
			    <div className="mv3">
			      <input 
			      onClick={onSubmit}
			      className="b mr1 ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
			      type="submit" value="Submit" />
			      <input 
			      onClick={() => setIsSignin(!isSignin)}
			      className="b ml1 ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
			      type="submit" value={`${isSignin ? 'Register' : 'Sign In'}`} />
			    </div>
			  </div>
			</main>
		</div>

	)

}

export default Login;