const authorizeUser = (remUser, setUser) => {
  const token = window.sessionStorage.getItem(process.env.REACT_APP_TOKEN);
  if(token){
    fetchAuthentication(token)
    .then(resp => {
      if(resp && resp.id){
        setUser(resp);
      }
    })
    .catch(console.warn);//Change this so someone wont know if brute force works
  }
}

async function fetchAuthentication(token){
  return await fetch('https://sevotechserver.xyz/login', {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        'Authorization': token
      }
    }).then(res => res.json())
    .then(data => Promise.resolve(data))
    .catch(err => Promise.reject(`Authorization.js, fetchAuthenticaton: ${err}`))
}

export {authorizeUser};

// module.exports = {
// 	authorizeUser: authorizeUser
// }