import React, {Component} from 'react';
import { Route, Switch, withRouter } from "react-router-dom";

// import Data from './temp/Data';

import Home from './Pages/Home/Home';
import Login from './Pages/login/login';
import ErrorBoundry from './Pages/Errors/ErrorBoundry';
import NavBar from './Components/Navigation/NavBar';
import NF from './Pages/Errors/NotFound';
// import Modal from './Controllers/Modal';
import {authorizeUser} from './Controllers/Authorization.js';
import './App.css';
//Code split
const VehiclePage = React.lazy(() => import('./Pages/VehiclePage/VehiclePage'));
const About = React.lazy(() => import('./Pages/About/About'));
const Contact = React.lazy(() => import('./Pages/Contact/Contact'));
const Catalogue = React.lazy(() => import('./Pages/Catalogue/Catalogue'));
const Footer = React.lazy(() => import('./Components/Footer/Footer'));
const Form = React.lazy(() => import('./Pages/Form/Form'));


const initialUser = {
  loggedIn: false,
  id: '',
  email: ''
}

//Make app a little smaller after fixes.
//Add redux maybe so no more complicated state tree

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: initialUser,
      vObject: null
    };
  }

  componentDidMount() {
    //No more cookies!!!!
    authorizeUser(this.logOut, this.signIn);
  }

  //Code Split
  //redux (being able to change state higher up so as to not loose data)
  getVehicle = (id, route) => {
    this.setState({vObject: null});
    if(id > 0){

      fetch('https://sevotechserver.xyz/vehicleid', {
        method: 'put',
        headers: {'content-Type': 'application/json'},
        body: JSON.stringify({
          id: id
        })
      })
      .then(res => res.json())
      .then(veh => this.setState({vObject: veh}))
      .catch(err => console.error(`Something went wrong receiving data. Report: ${err}`))
      
    }

    if(route){
      this.props.history.replace("/catalogue/menu")
    }
  }

  //Maybe push it out when redux is installed
  signIn = (data) => {
    if(data){
      this.setState({user: {
        loggedIn: true,
        id: data.id,
        email: data.email
      }});
    }
  }
  //redux
  initialVObject = () => {
    this.setState({vObject: null});
  }
  //redux
  logOut = (event) => {
    const token = process.env.REACT_APP_TOKEN;
    // console.log(token);
    if(window.sessionStorage.getItem(token))
    {
      postLogout(window.sessionStorage.getItem(token))
      .then(res => window.sessionStorage.removeItem(token))
      .catch(err => console.error(err));
    }
    this.setState({user: initialUser});
  }
  //redux
  vehiclePage = (id) => {
    this.getVehicle(id, false);
    this.props.history.push("/catalogue/vehichle");
  }

  render() { 
    // console.log(process.env.REACT_APP_TOKEN)
    // Quite chunky
    // console.log(UserProfile.getCookie('user'));
    return this.normal();
    
    // enable for maintanence 
    // if(this.state.user.loggedIn && window.sessionStorage.getItem(process.env.REACT_APP_TOKEN)){
    //   return this.normal();
    // }
    // else{
    //   return(
    //     <div className='app'>
    //         <NavBar onNavClick={this.onNavClick} navOpen={this.state.navOpen} isLoggedOn={this.state.user.loggedIn} logOut={this.logOut}/>
    //         <Switch>
    //           <Route path='/' render={() => <Login signIn={this.signIn} onRouteChange={this.onRouteChange}/>}
    //           />
    //           <Route component={NF} />
    //         </Switch>
    //     </div>
    //   )
    // }
    
  }

  normal = () => {
    return(
      <div className='app'>
        <ErrorBoundry>
            <NavBar onNavClick={this.onNavClick} navOpen={this.state.navOpen} isLoggedOn={this.state.user.loggedIn} logOut={this.logOut}/>
            <Switch>
              <Route path='/' exact component={() => <Home history={this.props.history} vPage={this.vehiclePage} isLoggedOn={this.state.user.loggedIn}/>} />
              <Route path="/about" exact component={About} />
              <Route path="/contact" exact component={Contact} />
              <Route path='/catalogue' exact component={() => <Catalogue isLoggedOn={this.state.user.loggedIn} menu={this.getVehicle} vehiclePage={this.vehiclePage}/>} />
              <Route path="/catalogue/vehichle" exact component={() => <VehiclePage data={this.state.vObject} />} />
              <Route path="/catalogue/menu" exact component={() => <Form data={this.state.vObject} isLoggedOn={this.state.user.loggedIn} history={this.props.history} reset={this.initialVObject}/> } />
              <Route 
              path='/login' 
              render={() => <Login signIn={this.signIn} onRouteChange={this.onRouteChange}/>}
              />
              <Route component={NF} />
            </Switch>
            <Footer />
        </ErrorBoundry>
      </div>
    );
  }
}

async function postLogout(token){
  return await fetch("https://sevotechserver.xyz/logout", {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        'Authorization': token}
    }).then(res => res.json())
    .then(data => Promise.resolve(data))
    .catch(err => Promise.reject(`App.js, postLogout: ${err}`));
}

// export default withRouter(App);
export default withRouter(App);